
// Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

* {
  font-weight: 600;
}
